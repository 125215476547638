import React from 'react'

function ThankYou(props) {
    console.log(props);
    return (
        <div>
            <h1>thank you</h1>
        </div>
    )
}

export default ThankYou
